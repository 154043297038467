import { Controller } from 'react-hook-form';
import { Checkbox, CheckboxProps, Container, FormControlLabel, Typography } from '@mui/material';
import { AppFieldProps } from '../types/components.type';
import { AppBox } from './AppBox';

export type AppCheckboxFieldProps = AppFieldProps & Omit<CheckboxProps, 'name'> & {
    label?: string;
};

export function AppCheckboxField(props: AppCheckboxFieldProps) {
    const { name, control, rules, defaultValue = false, label, ...rest } = props;

    return (
        <AppBox>
            <Controller
                name={name as string}
                control={control}
                rules={rules}
                defaultValue={defaultValue}
                render={({ field, fieldState }) => (
                    <Container>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    {...field}
                                    checked={!!field.value}
                                    onChange={(e) => field.onChange(e.target.checked)}
                                    {...rest}
                                />
                            }
                            label={label}
                        />
                        {fieldState.error && (
                            <Typography color="error">
                                {fieldState.error.message}
                            </Typography>
                        )}
                    </Container>

                )}
            />

        </AppBox>
    );
}
