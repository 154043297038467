export class ValidationUtils {

    static REQUIRED_FIELD_MESSAGE = 'Field is required';


    static isPositiveInteger(value: any) {
        const number = Number(value)
        return (ValidationUtils.isInteger(number) && number > 0) || 'Must be an positive integer';
    }

    static isInteger(value: any) {
        return (!ValidationUtils.isEmpty(value) && Number.isInteger(Number(value))) || 'Must be an integer';
    }

    static isEmpty(value: any) {
        return value === '' || value == null || value === undefined;
    }

    static emailValidation() {
        return {
            pattern: {
                value: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
                message: 'Invalid email address'
            }
        }
    }
}