import { AppTextField } from '../../components/AppTextField';
import { CRUDType } from '../../types/crud.type';
import EditFormComponent from '../common/EditFormComponent';
import { AppFileUploadField } from '../../components/AppFileUploadField';
import { MovementType } from '../../types/movement.type';
import { MovementsServices } from '../../services/movements.service';
import { ValidationUtils } from '../../utils/validations.utils';
import MovementStationsAssociationField, { MovementStationsAssociationFieldProps } from './MovementStationsAssociationField';

export function MovementEdit({ mode }: CRUDType) {
    return (
        <EditFormComponent<MovementType>
            title="Movement"
            mode={mode}
            backPath="/movements"
            service={{
                getById: MovementsServices.getById,
                add: MovementsServices.add,
                update: MovementsServices.update,
            }}
            formFields={[
                {
                    name: 'image',
                    component: AppFileUploadField,
                    props: {
                        rules: { required: 'Image is required' },
                        label: 'Choose Movement Image',
                    }
                },
                {
                    name: 'name',
                    component: AppTextField,
                    props: {
                        label: 'Name',
                        rules: {
                            required: 'Name is required'
                        },
                        columns: 6
                    }
                },
                {
                    name: 'pods',
                    component: AppTextField,
                    props: {
                        label: '# Pods',
                        rules: {
                            validate: ValidationUtils.isPositiveInteger
                        },
                        columns: 3
                    }
                },
                {
                    name: 'stations',
                    component: AppTextField,
                    props: {
                        label: '# Stations',
                        rules: {
                            validate: ValidationUtils.isPositiveInteger
                        },
                        columns: 3
                    }
                },
                {
                    name: 'movement_station_pods',
                    component: MovementStationsAssociationField,
                    props: {
                        type: 'pods',
                    } as MovementStationsAssociationFieldProps
                },
                {
                    name: 'movement_station_tvs',
                    component: MovementStationsAssociationField,
                    props: {
                        type: 'tvs',
                    } as MovementStationsAssociationFieldProps
                }
            ]}
        />
    );

}