import { Toolbar, Typography } from "@mui/material";
import { ReactNode } from "react";

export type AppToolbarProps = {
    title: string;
    children?: ReactNode
}

export function AppToolbar(props: AppToolbarProps) {
    const { title } = props;
    return (
        <Toolbar>
            <Typography variant="h6" component="div" style={{ flexGrow: 1 }}>
                {title}
            </Typography>
            {props.children}
        </Toolbar>
    )
}