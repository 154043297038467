
import { TargetAreasServices } from '../../services/targerareas.service';
import { TargetAreaType } from '../../types/targetareas.type';
import { AppTextField } from '../../components/AppTextField';
import { CRUDType } from '../../types/crud.type';
import EditFormComponent from '../common/EditFormComponent';

export function EditTargetArea({ mode }: CRUDType) {

    return (
        <EditFormComponent<TargetAreaType>
            title="Target Area"
            mode={mode}
            backPath="/targetareas"
            service={{
                getById: TargetAreasServices.getById,
                add: TargetAreasServices.add,
                update: TargetAreasServices.update,
            }}
            formFields={[
                { name: 'name', component: AppTextField, props: { label: 'Name', rules: { required: 'Name is required' } } }
            ]}
        />
    );

}