import React from 'react';
import { GridColDef } from '@mui/x-data-grid';
import { Container } from '@mui/material';
import { Add } from '@mui/icons-material';
import { AppDataGrid } from '../../components/AppDataGrid';
import { useNavigate } from 'react-router-dom';
import { useDialog } from '../../hooks/dialog.hook';
import { AppButton } from '../../components/AppButton';
import { AppToolbar } from '../../components/AppToolbar';
import { HttpResponse } from '../../services/base.service';

export type ListComponentProps<T> = {
    title: string;
    columns: GridColDef[];
    getAll: () => Promise<HttpResponse<T[]>>;
    deleteById: (id: number) => Promise<HttpResponse>;
}

export default function ListComponent<T>(props: ListComponentProps<T>) {
    const { title, columns, getAll, deleteById } = props;

    const [data, setData] = React.useState<any[]>();
    const navigate = useNavigate();
    const { showConfirmation } = useDialog();


    React.useEffect(() => {
        getAll().then((response: HttpResponse<any[]>) => {
            setData(response.data);
        });
    }, [getAll]);


    const handleAdd = () => {
        navigate('create')
    };

    const handleEdit = (id: number) => {
        navigate(`edit/${id}`);
    };

    const handleDelete = (id: number, name: string) => {
        showConfirmation(`Are you sure you want to delete the ${title} ${name}?`, () => {
            deleteById(id).then((response: HttpResponse) => {
                setData(data?.filter((ta: any) => ta.id !== id));
            });
        })
    };

    return (
        <Container>
            <AppToolbar title={title}>
                <AppButton
                    startIcon={<Add />}
                    onClick={handleAdd}
                >
                    Add {title}
                </AppButton>
            </AppToolbar>

            <AppDataGrid
                rows={data}
                columns={columns}
                onEdit={handleEdit}
                onDelete={handleDelete}
            />
        </Container>
    );
}
