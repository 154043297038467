import { EquipmentType } from '../types/equipment.type';
import { HTTPServices } from './base.service';

export class EquipmentsServices extends HTTPServices {
    private static BASE_ROUTE = 'equipments/';

    static getAll() {
        return EquipmentsServices.get<EquipmentType[]>(EquipmentsServices.BASE_ROUTE);
    }

    static add(data: EquipmentType) {
        return EquipmentsServices.post<EquipmentType>(EquipmentsServices.BASE_ROUTE, data);
    }
    static update(id: number, data: EquipmentType) {
        return EquipmentsServices.put<EquipmentType>(EquipmentsServices.BASE_ROUTE + id, data);
    }

    static getById(id: number) {
        return EquipmentsServices.get<EquipmentType>(EquipmentsServices.BASE_ROUTE + id);
    }

    static deleteById(id: number) {
        return EquipmentsServices.delete<EquipmentType[]>(EquipmentsServices.BASE_ROUTE + id);
    }
}
