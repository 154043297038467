import React from 'react';
import { AuthenticationServices, LoginRequestType } from '../services/authentication.service';
import { UsersType } from '../types/users.type';
import { HTTPServices } from '../services/base.service';

export type AuthContextType = {
    user: UsersType | null;
    error: string | null;
    isLoading: boolean;
    login: (data: LoginRequestType) => void;
    logout: () => void;
}

const AuthContext = React.createContext<AuthContextType>({
    user: null,
    error: null,
    isLoading: true,
    login: (data: LoginRequestType) => { },
    logout: () => { }
});

export const AuthProvider = ({ children }: React.PropsWithChildren) => {
    const [user, setUser] = React.useState<UsersType | null>(null);
    const [isLoading, setIsLoading] = React.useState(true);
    const [error, setError] = React.useState<string | null>(null);

    React.useEffect(() => {
        // The centralized HTTPServices should control when the app is executing a request
        HTTPServices.setIsLoading = setIsLoading;

        const fetchUser = async () => {
            try {
                // Fetch the user data using the token
                const resp = await AuthenticationServices.refreshToken();
                HTTPServices.accessToken = resp.data.accessToken;
                setUser((resp.data as any).user);
            } catch (error) {
                console.error('Failed to validate user:', error);
                setUser(null); // Clear user if there was an error
            } finally {
                setIsLoading(false); // Stop loading
            }
        };

        fetchUser();
    }, []);

    const login = async (data: LoginRequestType) => {
        try {
            setError(null)
            setIsLoading(true);

            const resp = await AuthenticationServices.signin(data);
            HTTPServices.accessToken = resp.data.accessToken;
            setUser(resp.data.user);

        } catch (error) {
            setError((error as any).response?.data?.error || 'Error trying to login')
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };

    const logout = async () => {
        try {
            await AuthenticationServices.logout();
            setUser(null);
            HTTPServices.accessToken = '';
        } catch (error) {
            console.error(error);
        }
    };

    const contextValue = {
        user,
        error,
        isLoading,
        login,
        logout,
    };

    return <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>;
};

export const useSession = () => {
    return React.useContext(AuthContext);
};