import ListComponent from '../common/ListComponent';
import { ExerciseTypesServices } from '../../services/exercisetypes.service';


export function ExerciseTypesList() {
    return (
        <ListComponent
            title='Exercise Types'
            getAll={ExerciseTypesServices.getAll}
            deleteById={ExerciseTypesServices.deleteById}
            columns={
                [
                    { field: 'name', headerName: 'Name', flex: 1 },
                    { field: 'description', headerName: 'Description', flex: 1 }
                ]
            }

        />
    );
};