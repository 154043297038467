import React from 'react';
import { ArrowBack } from '@mui/icons-material';
import { Container, Grid, GridSize } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { FieldValues, useForm } from 'react-hook-form';
import { useSnackbar } from '../../hooks/snackbar.hook';
import { AppButton } from '../../components/AppButton';
import { AppToolbar } from '../../components/AppToolbar';
import { HttpResponse } from '../../services/base.service';
import { AppFieldProps } from '../../types/components.type';
import { AppAutocompleteFieldProps } from '../../components/AppAutocompleteField';
import { AppFileUploadProps } from '../../components/AppFileUploadField';
import { AppTextFieldProps } from '../../components/AppTextField';
import { AppRadioGroupFieldProps } from '../../components/AppRadioGroup';

export type EditFormFieldType<T> = {
    name: keyof T,
    component: React.ElementType,
    props?: {
        columns?: GridSize;
        getControlValue?: (value: any) => any;
    } & (Pick<AppFieldProps, 'label' | 'rules' | 'onChange'> | AppAutocompleteFieldProps | AppFileUploadProps | AppTextFieldProps | AppRadioGroupFieldProps)
}

export type EditFormComponentProps<T> = {
    title: string;
    mode?: 'edit' | 'create';
    service: {
        getById: (id: number) => Promise<HttpResponse<T>>;
        add: (data: T) => Promise<HttpResponse<T>>;
        update: (id: number, data: T) => Promise<HttpResponse<T>>;
    };
    formFields: EditFormFieldType<T>[];
    backPath: string;
    defaultValues?: Partial<T>;
};

export type EditFormRoutingParams = {
    id: string;
};

export default function AddOrUpdateComponent<T extends FieldValues>(props: EditFormComponentProps<T>) {
    const { mode = 'create', title, service, defaultValues, formFields, backPath: redirectPath } = props;
    const { id } = useParams<EditFormRoutingParams>();
    const navigate = useNavigate();
    const { showSnackbar } = useSnackbar();
    const { control, handleSubmit, setValue } = useForm<T>(defaultValues);

    React.useEffect(() => {
        if (mode === 'edit' && id) {
            service.getById(Number(id)).then(response => {
                const data = response.data;

                formFields.forEach((element: EditFormFieldType<T>) => {
                    if (element && data[element.name] !== null && data[element.name] !== undefined) {
                        let v = data[element.name];
                        if (element?.props?.getControlValue) {
                            v = element.props.getControlValue(data[element.name]);
                        }
                        setValue(element.name as any, v);
                    }
                });
            });
        }
    }, [mode, id, service, setValue, formFields]);

    const onSubmit = async (data: T) => {
        try {
            if (mode === 'create') {
                await service.add(data);
            } else if (mode === 'edit' && id) {
                await service.update(Number(id), data);
            }
            const action = mode === 'create' ? 'created' : 'updated';
            showSnackbar(`${title} ${action} successfully!`, 'success');
            navigate(redirectPath);
        } catch (error) {
            const msg = (error as any).response?.data?.message || 'An error occurred';
            console.error(msg, error);
            showSnackbar(msg, 'error');
        }
    };

    const handleBack = () => {
        navigate(redirectPath);
    };

    return (
        <Container>
            <AppToolbar title={mode === 'edit' ? `Edit ${title}` : `Add ${title}`}>
                <AppButton startIcon={<ArrowBack />} onClick={handleBack}>
                    Back
                </AppButton>
            </AppToolbar>
            <Container>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Grid container spacing={2}>
                        {formFields.map((field, index) => {
                            const Component = field.component;
                            const { columns = 12, ...rest } = field.props || {};
                            return (
                                <Grid item xs={12} md={columns} key={index}>
                                    <Component
                                        key={index}
                                        name={field.name}
                                        control={control}
                                        {...rest}
                                    />
                                </Grid>
                            );
                        })}
                    </Grid>
                    <AppButton type="submit">
                        {mode === 'edit' ? 'Update' : 'Add'} {title}
                    </AppButton>
                </form>
            </Container>
        </Container>
    );
}
