import { ImageRenderer } from '../../components/renderers/image.renderer';
import { WorkoutsServices } from '../../services/workouts.service';
import ListComponent from '../common/ListComponent';

export function WorkoutsList() {
    return (
        <ListComponent
            title='Workouts'
            getAll={WorkoutsServices.getAll}
            deleteById={WorkoutsServices.deleteById}
            columns={
                [
                    {
                        field: 'image',
                        headerName: 'Logo',
                        flex: 0.3,
                        renderCell: ImageRenderer
                    },
                    {
                        field: 'name',
                        headerName: 'Name',
                        flex: 0.6
                    },
                    {
                        field: 'description',
                        headerName: 'Description',
                        flex: 1
                    },
                    {
                        field: 'movement',
                        headerName: 'Movement',
                        flex: 0.4,
                        renderCell: (params) => <span>{params.value?.name}</span>
                    },
                    {
                        field: 'workout_exercise_types',
                        headerName: 'Type',
                        flex: 0.4,
                        valueGetter: (row: any) => row.map((value: any) => value.exercise_type.name).join(', '),
                    }
                ]
            }

        />
    );
};