import React from 'react';
import {
    FormControlLabel, Checkbox,
    Button, Grid, Typography, Accordion, AccordionDetails,
    IconButton
} from '@mui/material';
import { AppToolbar } from '../../components/AppToolbar';
import { AppButton } from '../../components/AppButton';
import { Add, ArrowBack, Delete } from '@mui/icons-material';
import { useNavigate, useParams } from 'react-router-dom';
import { WorkoutsServices } from '../../services/workouts.service';
import { AppBox } from '../../components/AppBox';
import { AppAutocompleteField } from '../../components/AppAutocompleteField';
import { ClassesType, ClassSchedulesType, RecurrenceType } from '../../types/classes.type';
import { useForm, useWatch } from 'react-hook-form';
import { CRUDType } from '../../types/crud.type';
import { AppDateField } from '../../components/AppDateField';
import { ClassesServices } from '../../services/classes.service';
import { EditFormRoutingParams } from '../common/EditFormComponent';
import { useSnackbar } from '../../hooks/snackbar.hook';
import { AppTextField } from '../../components/AppTextField';
import { HttpResponse } from '../../services/base.service';
import { TrainersServices } from '../../services/trainers.service';
import { UsersType } from '../../types/users.type';
import { WorkoutType } from '../../types/workout.type';
import { DateUtils } from '../../utils/date.utils';

export function ClassEdit({ mode }: CRUDType) {
    const navigate = useNavigate();
    const { control, handleSubmit, setValue, getValues } = useForm<ClassesType & RecurrenceType>({});
    const formValues = useWatch({
        control,
        defaultValue: {},
    });
    const { id } = useParams<EditFormRoutingParams>();
    const { showSnackbar } = useSnackbar();


    const [isRecurring, setIsRecurring] = React.useState<any>(false);
    const [trainers, setTrainers] = React.useState<UsersType[]>();
    const [workouts, setWorkouts] = React.useState<WorkoutType[]>();


    React.useEffect(() => {
        if (mode == 'edit') {
            ClassesServices.getById(Number(id)).then((resp: HttpResponse<ClassesType>) => {
                const classData = resp.data;
                for (const [key, value] of Object.entries(classData)) {
                    setValue(key as keyof ClassesType, value);
                }

            });
        }

        TrainersServices.getAll().then((resp: HttpResponse<UsersType[]>) => {
            setTrainers(resp.data)
        });

        WorkoutsServices.getAll().then((resp: HttpResponse<WorkoutType[]>) => {
            setWorkouts(resp.data)
        })



        handleRequiredSchedule()
    }, []);

    const handleRequiredSchedule = (classData?: ClassesType) => {
        const currentSchedules = classData?.class_schedules || getValues('class_schedules') || [];
        if (currentSchedules.length < 1) {
            addSchedule();
        }
    };

    const addSchedule = () => {
        // Get the current schedules from the form state
        const currentSchedules = getValues('class_schedules') || [];
        // Add a new empty schedule object to the array
        const newSchedule: ClassSchedulesType = {};

        // Update the schedules in the form state
        setValue('class_schedules', [...currentSchedules, newSchedule]);
    };

    const handleRemoveSchedule = (index: number) => {
        const currentSchedules = getValues('class_schedules') || [];
        if (currentSchedules.length > 1) {
            currentSchedules.splice(index, 1);
            setValue('class_schedules', currentSchedules);
        } else {
            showSnackbar('At least one schedule is required.', 'warning');
        }
    };

    const handleSave = async (data: ClassesType) => {
        try {
            if (mode == 'create') {
                await ClassesServices.add(data)

            } else if (mode == 'edit' && id) {
                await ClassesServices.update(id, data)
            }
            const action = mode === 'create' ? 'created' : 'updated';
            showSnackbar(`Class ${action} successfully!`, 'success');
            handleBack();
        } catch (error) {
            const msg = (error as any).response?.data?.message || 'An error occurred';
            console.error(msg, error);
            showSnackbar(msg, 'error');
        }
    };

    const handleBack = () => {
        navigate('/classes');
    }

    const transformDate = (value: Date) => {
        return DateUtils.toISODateFormat(value);
    }

    return (
        <form onSubmit={handleSubmit(handleSave)}>
            <AppToolbar title={mode === 'edit' ? `Edit Class` : `Add Class`}>
                <Grid container spacing={2} style={{ flex: 0.3 }}>
                    <Grid item>
                        <AppButton type='submit'>
                            Save
                        </AppButton>
                    </Grid>
                    <Grid item>
                        <AppButton startIcon={<ArrowBack />} onClick={handleBack}>
                            Back
                        </AppButton>
                    </Grid>
                </Grid>
            </AppToolbar>
            <AppBox>
                <Grid container spacing={2}>
                    <Grid item xs={3}>
                        <AppDateField
                            name="date"
                            control={control}
                            label="Start Date"
                            rules={{ required: 'Date is required' }}
                            transformValue={transformDate}
                        />
                    </Grid>
                    {mode == 'create' && <Grid item xs={3}>
                        <FormControlLabel
                            control={<Checkbox checked={isRecurring} onChange={(e) => setIsRecurring(e.target.checked)} />}
                            label="Recurring"
                        />
                    </Grid>}
                    {isRecurring && (
                        <>
                            <Grid item xs={3}>
                                <AppDateField
                                    name="recurrence.end_date"
                                    control={control}
                                    label="End Date"
                                    rules={{ required: 'End Date is required' }}
                                    transformValue={transformDate}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <AppAutocompleteField
                                    control={control}
                                    multiple={false}
                                    name="recurrence.week_day"
                                    label="Day of the Week"
                                    service={{
                                        options: [
                                            { id: 0, name: 'Sunday' },
                                            { id: 1, name: 'Monday' },
                                            { id: 2, name: 'Tuesday' },
                                            { id: 3, name: 'Wednesday' },
                                            { id: 4, name: 'Thursday' },
                                            { id: 5, name: 'Friday' },
                                            { id: 6, name: 'Saturday' }
                                        ]
                                    }}
                                />
                            </Grid>
                        </>
                    )}
                </Grid>
            </AppBox>
            {/* Schedule Section */}
            <AppBox>
                <Grid container spacing={2}>
                    {formValues.class_schedules?.map((schedule: ClassSchedulesType, scheduleIndex: number) => (
                        <Grid item xs={12} key={scheduleIndex + schedule.start_time!!}>
                            <Accordion expanded>
                                <AppBox sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                                    <Typography sx={{ flexGrow: 1, textAlign: 'center' }}>
                                        Schedule {scheduleIndex + 1}
                                    </Typography>
                                    <IconButton
                                        color='error'
                                        onClick={() => handleRemoveSchedule(scheduleIndex)} sx={{ ml: 'auto' }}
                                    >
                                        <Delete />
                                    </IconButton>
                                </AppBox>
                                <AccordionDetails>
                                    <Grid container spacing={2}>
                                        <Grid item xs={2}>
                                            <AppTextField
                                                control={control}
                                                rules={{ required: 'Field is required' }}
                                                name={`class_schedules[${scheduleIndex}].start_time`}
                                                label="Time"
                                                type="time"
                                                inputProps={{
                                                    step: 300, // 5 min steps
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <AppAutocompleteField
                                                control={control}
                                                rules={{ required: 'Field is required' }}
                                                multiple={false}
                                                name={`class_schedules[${scheduleIndex}].workout_id`}
                                                label="Workout"
                                                service={{
                                                    options: workouts
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <AppAutocompleteField
                                                control={control}
                                                rules={{ required: 'Field is required' }}
                                                multiple={false}
                                                name={`class_schedules[${scheduleIndex}].trainer_id`}
                                                label="Trainer"
                                                service={{
                                                    options: trainers
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                        </Grid>
                    ))}
                    <Grid item xs={12}>
                        <Button startIcon={<Add />} onClick={addSchedule} variant="contained" sx={{ mt: 1, mb: 2 }}>
                            Add Schedule
                        </Button>
                    </Grid>
                </Grid>
            </AppBox>
        </form>
    );
};
