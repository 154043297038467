import { CircularProgress, Box } from '@mui/material';

export type AppLoaderProps = {
    isLoading?: boolean;
}

export function AppLoader(props: AppLoaderProps) {
    if (!props.isLoading) return null;
    return (
        <Box
            sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                zIndex: 999,
                backgroundColor: 'transparent',
            }}
        >
            <CircularProgress size={50} />
        </Box>
    );
};
