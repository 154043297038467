import { AppTextField } from '../../components/AppTextField';
import { CRUDType } from '../../types/crud.type';
import EditFormComponent from '../common/EditFormComponent';

import { AppFileUploadField } from '../../components/AppFileUploadField';
import { WorkoutType } from '../../types/workout.type';
import { WorkoutsServices } from '../../services/workouts.service';
import { AppAutocompleteField } from '../../components/AppAutocompleteField';
import { MovementsServices } from '../../services/movements.service';
import { ExerciseTypesServices } from '../../services/exercisetypes.service';
import WorkoutPodsField from './WorkoutPodsField';
import { ValidationUtils } from '../../utils/validations.utils';
import { AppRadioGroupField } from '../../components/AppRadioGroup';

export function EditWorkout({ mode }: CRUDType) {
    return (
        <EditFormComponent<WorkoutType>
            title="Workout"
            mode={mode}
            backPath="/workouts"
            service={{
                getById: WorkoutsServices.getById,
                add: WorkoutsServices.add,
                update: WorkoutsServices.update,
            }}
            formFields={[
                {
                    name: 'image',
                    component: AppFileUploadField,
                    props: {
                        label: 'Choose Workout Logo'
                    }
                },
                {
                    name: 'name',
                    component: AppTextField,
                    props: {
                        label: 'Name',
                        columns: 4,
                        rules: {
                            required: ValidationUtils.REQUIRED_FIELD_MESSAGE
                        }
                    }
                },
                {
                    name: 'movement_id',
                    component: AppAutocompleteField,
                    props: {
                        label: 'Movement',
                        multiple: false,
                        columns: 4,
                        service: {
                            load: MovementsServices.getAll
                        },
                        rules: {
                            required: ValidationUtils.REQUIRED_FIELD_MESSAGE
                        }
                    }
                },
                {
                    name: 'workout_exercise_types',
                    component: AppAutocompleteField,
                    props: {
                        label: 'Workout Type',
                        valueProperty: 'exercise_type_id',
                        multiple: true,
                        columns: 4,
                        service: {
                            load: ExerciseTypesServices.getAll
                        },
                        rules: {
                            required: ValidationUtils.REQUIRED_FIELD_MESSAGE
                        }
                    }
                },
                {
                    name: 'hydration_break',
                    component: AppTextField,
                    props: {
                        label: 'Hydration Break (s)',
                        columns: 2,
                        rules: {
                            validate: ValidationUtils.isInteger
                        }
                    }
                },
                {
                    name: 'hydration_type',
                    component: AppRadioGroupField,
                    props: {
                        columns: 6,
                        options: [
                            {
                                value: 'lap',
                                label: 'Hydrate on Lap Change'
                            },
                            {
                                value: 'pod',
                                label: 'Hydrate on Pod Change'
                            }
                        ]
                    }
                },
                {
                    name: 'description',
                    component: AppTextField,
                    props: {
                        label: 'Description',
                        multiline: true,
                        rows: 4,
                        columns: 12
                    }
                },
                {
                    name: 'workout_pods',
                    component: WorkoutPodsField
                },
            ]}
        />
    );

}