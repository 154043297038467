import React from 'react';
import { Container, Accordion, AccordionSummary, AccordionDetails, Typography, Grid, IconButton } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useDialog } from '../../hooks/dialog.hook';
import { HttpResponse } from '../../services/base.service';
import { Add, Delete, Edit, FitnessCenter, Visibility } from '@mui/icons-material';
import { DataGrid, GridActionsCellItem, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { AppToolbar } from '../../components/AppToolbar';
import { AppButton } from '../../components/AppButton';
import { ClassesServices } from '../../services/classes.service';
import { ClassesType } from '../../types/classes.type';
import { WorkoutType } from '../../types/workout.type';
import { UsersType } from '../../types/users.type';

export type ClassSchedule = {
    id: number;
    start_time: string;
    trainer_name: string;
    workout_name: string;
};

export type ClassData = {
    id: number;
    date: string;
    workout_name?: string;
    schedules: ClassSchedule[];
};

export type ClassesListProps = {
}

export function ClassesList(props: ClassesListProps) {
    const [data, setData] = React.useState<ClassesType[]>([]);
    const navigate = useNavigate();
    const { showConfirmation } = useDialog();

    React.useEffect(() => {
        ClassesServices.getAll().then((response: HttpResponse<ClassesType[]>) => {
            setData(response.data);
        });
    }, [])

    const handleAdd = () => {
        navigate('create');
    };

    const editClass = (id: number) => {
        navigate(`edit/${id}`);
    };

    const previewClass = (date: string, time: string) => {
        navigate(`preview/${date}/${time}`);
    }

    const editExercises = (classId: string, workoutId: string) => {
        navigate(`/classes/exercises/edit?class_id=${classId}&workout_id=${workoutId}`);
    }

    const deleteClass = (id: number, date: string) => {
        showConfirmation(`Are you sure you want to delete the ${date} class?`, () => {
            ClassesServices.deleteById(id).then((response: HttpResponse) => {
                setData(data.filter((c) => c.id !== id));
            });
        });
    };

    const deleteSchedule = (id: number, schedule: string, workout: string) => {
        showConfirmation(`Are you sure you want to delete the ${workout} class schedule to ${schedule}?`, () => {
            ClassesServices.deleteScheduleById(id).then((response: HttpResponse) => {
                setData(data.filter((c) => c.id !== id));
            });
        });
    };

    const scheduleColumns: GridColDef[] = [
        { field: 'start_time', headerName: 'Start Time', flex: 0.3 },
        {
            field: 'workout', headerName: 'Workout', flex: 1,
            renderCell: (params: GridRenderCellParams<any, WorkoutType>) => <span>{params.value?.name}</span>
        },
        {
            field: 'trainer', headerName: 'Trainer', flex: 1,
            renderCell: (params: GridRenderCellParams<any, UsersType>) => <span>{params.value?.name}</span>
        },
        {
            field: 'actions',
            headerName: 'Actions',
            flex: 0.2,
            sortable: false,
            renderCell: (params) => (
                <>
                    <GridActionsCellItem
                        icon={<FitnessCenter />}
                        label="Edit"
                        onClick={() => editExercises(params.row.class_id, params.row.workout_id)}
                    />
                    <GridActionsCellItem
                        icon={<Delete />}
                        label="Delete"
                        onClick={() => deleteSchedule(params.row.id, params.row.start_time, params.row.workout.name)}
                    />
                </>
            ),
        },
    ];

    const formatDate = (dateString: string) => {
        const date = new Date(dateString);
        const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'long', day: 'numeric' };
        return date.toLocaleDateString('en-US', options);
    };

    return (
        <Container>
            <AppToolbar title='Classes'>
                <AppButton
                    startIcon={<Add />}
                    onClick={handleAdd}
                >
                    Add Class
                </AppButton>
            </AppToolbar>
            {data.map((classData) => {
                const date = new Date(classData.date);
                const dayOfWeek = date.toLocaleDateString('en-US', { weekday: 'long' });
                const fullDate = formatDate(classData.date);
                return (
                    <Accordion key={classData.id} expanded>
                        <AccordionSummary>
                            <Grid container alignItems="center" justifyContent="space-between">
                                <Grid item>
                                    <Typography component="span" style={{ color: '#1976d2', marginRight: 8 }}>
                                        {dayOfWeek}
                                    </Typography>
                                    <Typography component="span" style={{ fontSize: 12 }}>
                                        {fullDate}
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <IconButton onClick={() => previewClass(classData.date, classData.class_schedules[0]?.start_time!!)}>
                                        <Visibility />
                                    </IconButton>
                                    <IconButton onClick={() => editClass(classData.id)}>
                                        <Edit />
                                    </IconButton>
                                    <IconButton onClick={() => deleteClass(classData.id, classData.date)}>
                                        <Delete />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </AccordionSummary>
                        <AccordionDetails>
                            <DataGrid
                                autoHeight
                                rows={classData.class_schedules}
                                columns={scheduleColumns}
                                disableColumnMenu
                                hideFooter
                                columnHeaderHeight={0}
                                getRowClassName={(params) =>
                                    params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
                                }
                                sx={{

                                    '& .MuiDataGrid-row:nth-of-type(odd)': {
                                        backgroundColor: 'rgba(235, 235, 235, .7)',
                                    },
                                    '& .MuiDataGrid-row:nth-of-type(even)': {
                                        backgroundColor: 'white',
                                    }
                                }}
                            />
                        </AccordionDetails>
                    </Accordion>
                )
            })}
        </Container>
    );
};