import { Delete, Edit } from '@mui/icons-material';
import { DataGrid, DataGridProps, GridActionsCellItem, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import React from 'react';


export type AppDataGridProps = {
    onEdit?: (id: number) => void;
    onDelete?: (id: number, name: string) => void;
} & DataGridProps;

export function AppDataGrid(props: AppDataGridProps) {
    const { columns, onEdit, onDelete, ...rest } = props;

    // Memoize the actions column definition
    const actionsColumn: GridColDef = React.useMemo(() => ({
        field: 'actions',
        headerName: 'Actions',
        sortable: false,
        renderCell: (params: GridRenderCellParams) => (
            <>
                {onEdit && <GridActionsCellItem
                    icon={<Edit />}
                    label="Edit"
                    onClick={() => onEdit(params.row.id)}
                />}
                {onDelete && <GridActionsCellItem
                    icon={<Delete />}
                    label="Delete"
                    onClick={() => onDelete(params.row.id, params.row.name)}
                />}
            </>
        ),
    }), [onEdit, onDelete]);

    // Combine the existing columns with the actions column if needed
    const columnsWithActions = onEdit || onDelete ? [...columns, actionsColumn] : columns;


    return (
        <div style={{ height: '70vh', width: '100%' }}>
            <DataGrid
                sx={
                    {
                        '& .MuiDataGrid-columnHeaderTitle': {
                            fontWeight: 'bold'
                        },
                        '& .MuiDataGrid-columnSeparator': {
                            visibility: 'visible',
                            color: 'rgba(224, 224, 224, 1)', // Color of the column separators
                        }
                    }
                }
                columns={columnsWithActions}
                {...rest}
            />
        </div>
    )
}