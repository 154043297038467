import { AppTextField } from '../../components/AppTextField';
import { CRUDType } from '../../types/crud.type';
import EditFormComponent from '../common/EditFormComponent';
import { EquipmentsServices } from '../../services/equipments.service';
import { EquipmentType } from '../../types/equipment.type';
import { AppFileUploadField } from '../../components/AppFileUploadField';

export function EditEquipment({ mode }: CRUDType) {
    return (
        <EditFormComponent<EquipmentType>
            title="Equipments"
            mode={mode}
            backPath="/equipments"
            service={{
                getById: EquipmentsServices.getById,
                add: EquipmentsServices.add,
                update: EquipmentsServices.update,
            }}
            formFields={[
                { name: 'name', component: AppTextField, props: { label: 'Name', rules: { required: 'Name is required' } } },
                { name: 'description', component: AppTextField, props: { label: 'Description' } },
                { name: 'image', component: AppFileUploadField, props: { label: 'Choose Equipment Image' } },
            ]}
        />
    );

}