import { HTTPServices } from './base.service';
import { TargetAreaType } from '../types/targetareas.type';

export class TargetAreasServices extends HTTPServices {
    private static BASE_ROUTE = 'target-areas/';

    static getAll() {
        return TargetAreasServices.get<TargetAreaType[]>(TargetAreasServices.BASE_ROUTE);
    }

    static add(data: TargetAreaType) {
        return TargetAreasServices.post<TargetAreaType>(TargetAreasServices.BASE_ROUTE, data);
    }
    static update(id: number, data: TargetAreaType) {
        return TargetAreasServices.put<TargetAreaType>(TargetAreasServices.BASE_ROUTE + id, data);
    }

    static getById(id: number) {
        return TargetAreasServices.get<TargetAreaType>(TargetAreasServices.BASE_ROUTE + id);
    }

    static deleteById(id: number) {
        return TargetAreasServices.delete<TargetAreaType[]>(TargetAreasServices.BASE_ROUTE + id);
    }
}
