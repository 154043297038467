import { MovementType } from '../types/movement.type';
import { HTTPServices } from './base.service';

export class MovementsServices extends HTTPServices {
    private static BASE_ROUTE = 'movements/';

    static getAll() {
        return MovementsServices.get<MovementType[]>(MovementsServices.BASE_ROUTE);
    }

    static add(data: MovementType) {
        return MovementsServices.post<MovementType>(MovementsServices.BASE_ROUTE, data);
    }
    static update(id: number, data: MovementType) {
        return MovementsServices.put<MovementType>(MovementsServices.BASE_ROUTE + id, data);
    }

    static getById(id: number) {
        return MovementsServices.get<MovementType>(MovementsServices.BASE_ROUTE + id);
    }

    static deleteById(id: number) {
        return MovementsServices.delete<MovementType[]>(MovementsServices.BASE_ROUTE + id);
    }
}
