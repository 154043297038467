import { UsersType } from '../types/users.type';
import { HTTPServices } from './base.service';

export class TrainersServices extends HTTPServices {
    private static BASE_ROUTE = 'trainers/';

    static getAll() {
        return TrainersServices.get<UsersType[]>(TrainersServices.BASE_ROUTE);
    }

    static add(data: UsersType) {
        return TrainersServices.post<UsersType>(TrainersServices.BASE_ROUTE, data);
    }

    static update(id: number, data: UsersType) {
        return TrainersServices.put<UsersType>(TrainersServices.BASE_ROUTE + id, data);
    }

    static getById(id: number) {
        return TrainersServices.get<UsersType>(TrainersServices.BASE_ROUTE + id);
    }

    static deleteById(id: number) {
        return TrainersServices.delete<UsersType[]>(TrainersServices.BASE_ROUTE + id);
    }
}
