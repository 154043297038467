import { ClassesType, ClassWorkoutExercisesType } from '../types/classes.type';
import { HTTPServices } from './base.service';

export type UpdateCLassExerciseProps = {
    class_id: number;
    workout_id: number;
    station_number: number;
    exercise_id: number;
}

export class ClassesServices extends HTTPServices {
    private static BASE_ROUTE = 'classes/';

    static getAll() {
        return ClassesServices.get<ClassesType[]>(ClassesServices.BASE_ROUTE);
    }

    static add(data: ClassesType) {
        return ClassesServices.post<ClassesType>(ClassesServices.BASE_ROUTE, data, 'raw');
    }

    static update(id: string, data: ClassesType) {
        return ClassesServices.put<ClassesType>(ClassesServices.BASE_ROUTE + id, data, 'raw');
    }

    static getById(id: number) {
        return ClassesServices.get<ClassesType>(ClassesServices.BASE_ROUTE + id);
    }

    static deleteById(id: number) {
        return ClassesServices.delete<ClassesType[]>(ClassesServices.BASE_ROUTE + id);
    }

    static deleteScheduleById(id: number) {
        return ClassesServices.delete<ClassesType[]>(ClassesServices.BASE_ROUTE + 'schedule/' + id);
    }

    static getClassExercises(classId: number, workoutId: number) {
        return ClassesServices.get<ClassWorkoutExercisesType[]>(ClassesServices.BASE_ROUTE + `workout/exercises?workout_id=${workoutId}&class_id=${classId}`);
    }

    static updateClassExercise(data: UpdateCLassExerciseProps) {
        return ClassesServices.put<ClassWorkoutExercisesType[]>(ClassesServices.BASE_ROUTE + `workout/exercise`, data, 'raw');
    }


}
