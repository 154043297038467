import { TargetAreasServices } from '../../services/targerareas.service';
import ListComponent from '../common/ListComponent';

export function TargetAreasList() {
    return (
        <ListComponent
            title='Target Areas'
            getAll={TargetAreasServices.getAll}
            deleteById={TargetAreasServices.deleteById}
            columns={
                [
                    { field: 'name', headerName: 'Name', flex: 1 }
                ]
            }

        />
    );
}
