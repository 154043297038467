import { ImageRenderer } from '../../components/renderers/image.renderer';
import { EquipmentsServices } from '../../services/equipments.service';
import ListComponent from '../common/ListComponent';

export function EquipmentsList() {
    return (
        <ListComponent
            title='Equipments'
            getAll={EquipmentsServices.getAll}
            deleteById={EquipmentsServices.deleteById}
            columns={
                [
                    { field: 'image', headerName: 'Image', flex: 0.2, renderCell: ImageRenderer },
                    { field: 'name', headerName: 'Name', flex: 1 },
                    { field: 'description', headerName: 'Description', flex: 1 },
                ]
            }

        />
    );
};