import React from 'react';
import { Container, Grid, Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { AppToolbar } from '../../components/AppToolbar';
import { AppButton } from '../../components/AppButton';
import { ArrowBack } from '@mui/icons-material';

export type ClassPreviewParams = {
    date: string;
    time: string;
};

export function ClassesPreview() {
    const { date, time } = useParams<ClassPreviewParams>();
    const navigate = useNavigate();

    const handleBack = () => {
        navigate('/classes');
    }

    // Base URL for the TV displays
    const baseUrl = 'https://displays.dohiit.pt';

    // URLs for each TV type
    const exerciseTv1Url = `${baseUrl}/exercise?tv=1&class_schedule_date=${date}&class_schedule_time=${time}`;
    const exerciseTv2Url = `${baseUrl}/exercise?tv=2&class_schedule_date=${date}&class_schedule_time=${time}`;
    const exerciseTv3Url = `${baseUrl}/exercise?tv=3&class_schedule_date=${date}&class_schedule_time=${time}`;
    const timerTvUrl = `${baseUrl}/timer?tv=4&class_schedule_date=${date}&class_schedule_time=${time}`;
    const scheduleTvUrl = `${baseUrl}/schedule?tv=5&class_schedule_date=${date}&class_schedule_time=${time}`;
    const movementTvUrl = `${baseUrl}/movement?tv=6&class_schedule_date=${date}&class_schedule_time=${time}`;

    const horizontalStyle = {
        width: '1920px',
        height: '1080px',
        transform: 'scale(0.3)', // Scale to fit
        transformOrigin: 'top left',
    };

    const verticalStyle = {
        width: '1080px',
        height: '1920px',
        transform: 'scale(0.3)', // Scale to fit
        transformOrigin: 'top left',
    };

    return (
        <Container>
            <AppToolbar title={`Preview Class workout`}>
                <AppButton startIcon={<ArrowBack />} onClick={handleBack}>
                    Back
                </AppButton>
            </AppToolbar>

            {/* Bottom section with 3 Exercise TVs */}
            <Grid container spacing={2} style={{ height: 700 }}>
                {/* Exercise TV 1 */}
                <Grid item xs={4}>
                    <Typography variant="h6" gutterBottom>Exercise TV 1</Typography>

                    <iframe src={exerciseTv1Url} style={verticalStyle} title="Exercise TV 1" />

                </Grid>
                {/* Exercise TV 2 */}
                <Grid item xs={4}>
                    <Typography variant="h6" gutterBottom>Exercise TV 2</Typography>
                    <iframe src={exerciseTv2Url} style={verticalStyle} title="Exercise TV 2" />

                </Grid>
                {/* Exercise TV 3 */}
                <Grid item xs={4}>
                    <Typography variant="h6" gutterBottom>Exercise TV 3</Typography>
                    <iframe src={exerciseTv3Url} style={verticalStyle} title="Exercise TV 3" />
                </Grid>
            </Grid>
            {/* Header section with Timer, Movement, and Schedule TVs */}
            {/* Timer TV */}
            <Container style={{ height: 500 }}>
                <Typography variant="h6" gutterBottom>Timer TV</Typography>
                <iframe src={timerTvUrl} style={horizontalStyle} title="Timer TV" />
            </Container>

            {/* Movement TV */}
            <Container style={{ height: 500 }}>
                <Typography variant="h6" gutterBottom>Movement TV</Typography>
                <iframe src={movementTvUrl} style={horizontalStyle} title="Movement TV" />
            </Container>

            {/* Schedule TV */}
            <Container>
                <Typography variant="h6" gutterBottom>Schedule TV</Typography>
                <iframe src={scheduleTvUrl} style={horizontalStyle} title="Schedule TV" />
            </Container>
        </Container>
    );
}
