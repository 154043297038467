import axios, { AxiosError, AxiosResponse, AxiosInstance, AxiosRequestConfig } from 'axios';

export interface HttpResponse<T = any> extends AxiosResponse<T> { };

type ContentType = 'form-data' | 'raw';

export class HTTPServices {
    protected static httpClient: AxiosInstance;
    public static accessToken: string | undefined;
    protected static REFRESH_TOKEN_PATH = 'auth/refreshtoken'
    public static setIsLoading: (isLoading: boolean) => void;

    static init() {
        // avoid re-create axios instances
        if (HTTPServices.httpClient !== undefined) {
            return;
        }
        // Axios instance configuration
        HTTPServices.httpClient = axios.create({
            baseURL: process.env.REACT_APP_SERVER_API_BASE_PATH,
            responseType: 'json',
            withCredentials: true
        });

        // Interceptor to handle Error responses
        HTTPServices.httpClient.interceptors.response.use(
            (response: AxiosResponse) => {
                HTTPServices.setIsLoading(false);  // Hide loader on success
                return response;
            },
            async (error: AxiosError) => {
                HTTPServices.setIsLoading(false);  // Hide loader on error
                console.log('Error received:', error);  // Log the error first
                const originalRequest = error.config as any;

                // Check if the error is due to authentication and if the request hasn't been retried yet
                if (originalRequest && originalRequest.url !== HTTPServices.REFRESH_TOKEN_PATH && (error.response?.status === 401 || error.response?.status === 403) && !originalRequest._retry) {
                    console.log('Attempting to refresh token...');
                    originalRequest._retry = true; // Mark the request as retried

                    try {
                        // Attempt to refresh the token
                        const response = await HTTPServices.refreshToken();
                        console.log('Token refreshed successfully:', response);
                        HTTPServices.accessToken = response.data.accessToken;

                        // Retry the original request
                        return HTTPServices.httpClient(originalRequest);
                    } catch (refreshError) {
                        console.log('Token refresh failed:', refreshError);
                        // If the refresh token request fails, redirect to the login page or handle accordingly
                        window.location.href = '/';
                        return Promise.reject(refreshError);
                    }
                }

                // If it's another type of error or the request has already been retried, reject the error
                console.log('Error is not recoverable, rejecting:', error);
                return Promise.reject(error);
            }
        );

        // Interceptor to handle the access token
        HTTPServices.httpClient.interceptors.request.use(
            (config) => {
                HTTPServices.setIsLoading(true);  // Show loader on request start
                const token = HTTPServices.accessToken;
                if (token) {
                    config.headers['Authorization'] = `Bearer ${token}`;
                }
                return config;
            },
            (error) => {
                HTTPServices.setIsLoading(false); // Hide loader on error
                return Promise.reject(error);
            }
        );
    }

    static async refreshToken() {
        return this.get<{ accessToken: string }>(HTTPServices.REFRESH_TOKEN_PATH);
    }

    static toFormData(data: object = {}) {
        const formData = new FormData();
        Object.entries(data).forEach(([key, value]) => {
            // eslint-disable-next-line
            if (value != undefined) {
                if (Array.isArray(value)) {
                    formData.append(key, JSON.stringify(value));
                } else {
                    formData.append(key, value);
                }
            }
        });
        return formData;
    }

    static toFormDataConfig(config: AxiosRequestConfig = {}) {
        return { ...config, headers: { 'Content-Type': 'multipart/form-data' } }
    }

    static toJsonConfig(config: AxiosRequestConfig = {}) {
        return { ...config, headers: { 'Content-Type': 'application/json' } };
    }

    protected static get<T, R = HttpResponse<T>>(endpoint: string, config?: AxiosRequestConfig) {
        return HTTPServices.httpClient.get<T, R>(endpoint, config);
    }

    protected static delete<T, R = HttpResponse<T>>(endpoint: string, config?: AxiosRequestConfig) {
        return HTTPServices.httpClient.delete<T, R>(endpoint, config);
    }
    protected static post<T, R = HttpResponse<T>>(endpoint: string, data: any, contentType: ContentType = 'form-data', config?: AxiosRequestConfig) {
        const { payload, dataConfig } = HTTPServices.prepareRequest(data, contentType, config);
        return HTTPServices.httpClient.post<T, R>(endpoint, payload, dataConfig);
    }

    protected static put<T, R = HttpResponse<T>>(endpoint: string, data?: any, contentType: ContentType = 'form-data', config?: AxiosRequestConfig) {
        const { payload, dataConfig } = HTTPServices.prepareRequest(data, contentType, config);
        return HTTPServices.httpClient.put<T, R>(endpoint, payload, dataConfig);
    }

    // Generalized method to prepare payload and config
    private static prepareRequest(data: any, contentType: ContentType, config?: AxiosRequestConfig) {
        const payload = contentType === 'form-data' ? HTTPServices.toFormData(data) : JSON.stringify(data);
        const dataConfig = contentType === 'form-data' ? HTTPServices.toFormDataConfig(config) : HTTPServices.toJsonConfig(config);
        return { payload, dataConfig };
    }
}

HTTPServices.init();