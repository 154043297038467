import React from 'react';
import { Autocomplete, Checkbox, Grid, TextField, Typography } from '@mui/material';
import { Controller, useWatch } from 'react-hook-form';
import { TvType } from '../../types/tvs.type';
import { TVsServices } from '../../services/tvs.service';
import { HttpResponse } from '../../services/base.service';
import { AppBox } from '../../components/AppBox';
import { MovementStationPodsType, MovementStationTvType } from '../../types/movement.type';
import { AppFieldProps } from '../../types/components.type';

export type MovementStationsAssociationFieldProps = {
    type: 'tvs' | 'pods';
} & AppFieldProps

type CustomOptionType = {
    id: number;
    station_number: number;
    tv_id?: number;
    pod_number?: number;
}

const MovementStationsAssociationField: React.FC<MovementStationsAssociationFieldProps> = (props: MovementStationsAssociationFieldProps) => {
    const { control, type } = props;

    const controlName = 'movement_station_' + type;
    const [items, setItems] = React.useState<any[]>([]);
    const [stations, setStations] = React.useState<any[]>([]);
    const [selectedStations, setSelectedStations] = React.useState<{ [key: string]: CustomOptionType[] }>({});

    const stationsCount = useWatch({
        control,
        name: 'stations',
        defaultValue: control._formValues['stations'] || 0,
    });

    const value = useWatch({
        control,
        name: controlName,
        defaultValue: control._formValues[controlName] || []
    });

    const podsCount = useWatch({
        control,
        name: 'pods',
        defaultValue: control._formValues['pods'] || 0
    });


    /* Should be executed only when the object content realy changes, not the reference! */
    React.useEffect(() => {
        const tvSelections = value.reduce((acc: { [key: string]: any[] }, current: MovementStationTvType | MovementStationPodsType) => {
            const item_id = type == 'tvs' ? (current as MovementStationTvType).tv_id : (current as MovementStationPodsType).pod_number;
            if (!acc[item_id]) {
                acc[item_id] = [];
            }
            acc[item_id].push(current);
            return acc;
        }, {});

        setSelectedStations(tvSelections);
    }, [JSON.stringify(value)]);

    React.useEffect(() => {
        if (type == 'tvs') {
            TVsServices.getByType('exercise').then((response: HttpResponse<TvType[]>) => {
                setItems(response.data);
            });
        }
    }, []);

    React.useEffect(() => {
        if (type == 'pods') {
            setItems(Array.from({ length: podsCount }, (_, i) => ({ id: i + 1, name: `Pod ${i + 1}` })));
        }
    }, [podsCount]);

    React.useEffect(() => {
        const stations = Array.from({ length: stationsCount }, (_, i) => {
            return {
                station_number: i + 1
            }
        });
        setStations(stations);


        // Clear stations that don't exist anymore
        setSelectedStations(prevState => {
            const updatedSelectedStations = { ...prevState };
            Object.keys(updatedSelectedStations).forEach(tvId => {
                updatedSelectedStations[tvId] = updatedSelectedStations[tvId].filter(station => station.station_number <= stationsCount);
            });
            updateControlValue(Object.values(updatedSelectedStations).flat());
            return updatedSelectedStations;
        });

    }, [stationsCount]);

    const handleChange = (itemId: string, selectedStationsForItem: CustomOptionType[]) => {
        setSelectedStations(prevState => ({
            ...prevState,
            [itemId]: selectedStationsForItem
        }));

        const updatedValues = Object.entries({ ...selectedStations, [itemId]: selectedStationsForItem })
            .flatMap(([itemId, stations]) => stations.map(station => (
                {
                    id: station.id,
                    station_number: station.station_number,
                    tv_id: type == 'tvs' ? parseInt(itemId, 10) : undefined,
                    pod_number: type == 'pods' ? parseInt(itemId, 10) : undefined
                })));

        updateControlValue(updatedValues);
    };

    function updateControlValue(updatedValues: CustomOptionType[]) {
        control._formValues[controlName] = updatedValues;
    }

    const getFilteredStations = (tvId: string) => {
        const selectedStationIds = Object.values(selectedStations).flat().map(station => station.station_number);
        const currentTvSelectedStations = selectedStations[tvId] || [];

        return stations.filter(station => !selectedStationIds.includes(station.station_number) || currentTvSelectedStations.some(s => s.station_number == station.station_number));
    };

    return (
        <AppBox>
            <Controller
                name={controlName}
                control={control}
                rules={{
                    validate: (value: any = []) => {
                        return value.length == stationsCount || `All the stations must be associated with a ${type}!`;
                    }
                }}
                render={({ field, fieldState }) => (
                    <>
                        {fieldState.error && (
                            <AppBox>
                                <Typography color="error">
                                    {fieldState.error.message}
                                </Typography>
                            </AppBox>
                        )}
                        <Grid container spacing={2}>
                            {stationsCount > 0 && items.map((item) => (
                                <Grid item xs={4} key={type + '.' + item.id}>
                                    <Autocomplete
                                        multiple
                                        options={getFilteredStations(item.id.toString())}
                                        getOptionLabel={(option: any) => option.station_number.toString()}
                                        isOptionEqualToValue={(option: any, value: any) => option.station_number == value.station_number}
                                        onChange={(_, selectedStations) => {
                                            handleChange(item.id.toString(), selectedStations as CustomOptionType[]);
                                        }}
                                        value={selectedStations[item.id.toString()] || []}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label={`${item.name}`}
                                                variant="outlined"
                                                fullWidth
                                            />
                                        )}
                                        /* Promote to the appAutocomplete when it's multiple */
                                        disableCloseOnSelect
                                        renderOption={(props, option, { selected }) => (
                                            <li {...props}>
                                                <Checkbox
                                                    style={{ marginRight: 8 }}
                                                    checked={selected}
                                                />
                                                {option.station_number}
                                            </li>
                                        )}
                                    />
                                </Grid>
                            ))}

                        </Grid>
                    </>

                )}
            />
        </AppBox>
    );
};

export default MovementStationsAssociationField;


