import { AppTextField } from '../../components/AppTextField';
import { CRUDType } from '../../types/crud.type';
import EditFormComponent, { EditFormFieldType } from '../common/EditFormComponent';
import { UsersType } from '../../types/users.type';
import { AppFileUploadField } from '../../components/AppFileUploadField';
import { ValidationUtils } from '../../utils/validations.utils';
import { TrainersServices } from '../../services/trainers.service';

type FormType = UsersType & { password_confirm?: string }

export function EditTrainers({ mode }: CRUDType) {

    const fields: EditFormFieldType<FormType>[] = [
        { name: 'profile_image', component: AppFileUploadField, props: { label: 'Choose trainer Profile Image' } },
        { name: 'name', component: AppTextField, props: { label: 'Name', rules: { required: 'Field is required' }, columns: 6 } },
        { name: 'job_title', component: AppTextField, props: { label: 'Job Title', columns: 6 } },
        {
            name: 'email',
            component: AppTextField,
            props: {
                label: 'Email',
                rules: {
                    required: 'Field is required',
                    ...ValidationUtils.emailValidation()
                }
            }
        }
    ]

    if (mode === 'create') {
        fields.push({
            name: 'password',
            component: AppTextField,
            props: {
                label: 'Password',
                type: 'password',
                rules: {
                    required: 'Field is required'
                },
                columns: 6
            }
        },
            {
                name: 'password_confirm',
                component: AppTextField,
                props: {
                    label: 'Confirm Password',
                    type: 'password',
                    rules:
                    {
                        required: 'Field is required',
                        validate: (value: string, data: any) =>
                            data.password === value || 'Passwords do not match'

                    },
                    columns: 6
                }
            })
    }


    return (
        <EditFormComponent<FormType>
            title="Trainers"
            mode={mode}
            backPath="/trainers"
            service={{
                getById: TrainersServices.getById,
                add: TrainersServices.add,
                update: TrainersServices.update,
            }}
            formFields={fields}
        />
    );

}