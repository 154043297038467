import { Controller } from 'react-hook-form';
import { Container, Typography } from '@mui/material';
import { DatePicker, DatePickerProps } from '@mui/x-date-pickers/DatePicker';
import { AppFieldProps } from '../types/components.type';
import { AppBox } from './AppBox';
import dayjs from 'dayjs';

export type AppDateFieldProps = AppFieldProps & Omit<DatePickerProps<any>, 'renderInput'>;

export function AppDateField(props: AppDateFieldProps) {
    const { name, control, rules, defaultValue = null, transformValue, ...rest } = props;

    return (
        <AppBox>
            <Controller
                name={name as string}
                control={control}
                rules={rules}
                defaultValue={defaultValue}
                render={({ field, fieldState }) => {
                    return (
                        <Container>
                            <DatePicker
                                {...field}
                                {...rest}
                                value={field.value ? dayjs(field.value as string) : null}
                                onChange={(date: dayjs.Dayjs) => {
                                    const d = date.toDate();
                                    field.onChange(transformValue ? transformValue(d) : d)
                                }}
                            />
                            {fieldState.error && (
                                <Typography color="error">
                                    {fieldState.error.message}
                                </Typography>
                            )}
                        </Container>
                    )
                }}
            />
        </AppBox>
    );
}
