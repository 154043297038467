import React from 'react';
import { IconButton, Typography } from '@mui/material';
import { Controller, useWatch } from 'react-hook-form';
import { AppFieldProps } from '../types/components.type';
import { AppBox } from './AppBox';
import { AppButton } from './AppButton';
import DeleteIcon from '@mui/icons-material/Delete';
import { ImageUtils } from '../utils/image.utils';
import { ConfigService } from '../services/config.service';


export type AppFileUploadProps = {
    accept?: 'video' | 'image';
} & AppFieldProps;

export function AppFileUploadField(props: AppFileUploadProps) {
    const { name, control, label, rules, accept = 'image' } = props;
    const [preview, setPreview] = React.useState<string | null>(null);
    const fileInputRef = React.useRef<HTMLInputElement>(null);

    // Watch the field value
    const fieldValue = useWatch({
        control,
        name,
        defaultValue: control._formValues[name] || null,
    });

    React.useEffect(() => {
        if (fieldValue) {
            setPreview(ConfigService.FILE_STORAGE_URL + fieldValue);
        }
    }, [fieldValue]);

    const handlePreview = (file: File) => {
        const reader = new FileReader();
        reader.onloadend = () => {
            setPreview(reader.result as string);
        };
        reader.readAsDataURL(file);
    };

    const handleRemove = (field: any) => {
        setPreview(null);
        if (fileInputRef.current) {
            fileInputRef.current.value = '';
        }
        field.onChange(null);
    };

    return (
        <AppBox display="flex" alignItems="center">
            <Controller
                name={name}
                control={control}
                rules={rules}
                render={({ field, fieldState }) => (
                    <AppBox display='flex' flexDirection="column" alignItems="center">
                        <>
                            {preview && <IconButton
                                onClick={() => handleRemove(field)}
                                aria-label="delete"
                                color='error'
                            >
                                <DeleteIcon />
                            </IconButton>
                            }
                            {accept === 'video' ?
                                <AppBox style={{ height: '150px' }}>
                                    <video
                                        controls
                                        src={preview || ''}
                                        style={{ height: '150px' }}
                                    />
                                </AppBox>
                                :
                                <AppBox style={{ height: '150px' }}>
                                    < img
                                        src={preview || ImageUtils.DEFAULT_IMAGE}
                                        alt="Preview"
                                        style={{ maxHeight: '150px' }}
                                    />
                                </AppBox>
                            }
                        </>
                        <input
                            accept={accept === 'image' ? 'image/*' : 'video/*'}
                            style={{ display: 'none' }}
                            type="file"
                            ref={fileInputRef}
                            onChange={(e) => {
                                const file = e.target.files?.[0];
                                if (file) {
                                    handlePreview(file);
                                    // Send the file to react-hook-form
                                    field.onChange(file);
                                }
                            }}
                        />
                        <AppButton onClick={() => fileInputRef.current?.click()}>
                            {label || 'Choose Image'}
                        </AppButton>
                        {fieldState.error && (
                            <Typography color="error">
                                {fieldState.error.message}
                            </Typography>
                        )}

                    </AppBox>
                )}
            />
        </AppBox>
    );
}
