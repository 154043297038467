import { HTTPServices } from './base.service';
import { ExerciseTypesType } from '../types/exercisetypes.type';

export class ExerciseTypesServices extends HTTPServices {
    private static BASE_ROUTE = 'exercise-types/';

    static getAll() {
        return ExerciseTypesServices.get<ExerciseTypesType[]>(ExerciseTypesServices.BASE_ROUTE);
    }

    static add(data: ExerciseTypesType) {
        return ExerciseTypesServices.post<ExerciseTypesType>(ExerciseTypesServices.BASE_ROUTE, data);
    }

    static update(id: number, data: ExerciseTypesType) {
        return ExerciseTypesServices.put<ExerciseTypesType>(ExerciseTypesServices.BASE_ROUTE + id, data);
    }

    static getById(id: number) {
        return ExerciseTypesServices.get<ExerciseTypesType>(ExerciseTypesServices.BASE_ROUTE + id);
    }

    static deleteById(id: number) {
        return ExerciseTypesServices.delete<ExerciseTypesType>(ExerciseTypesServices.BASE_ROUTE + id);
    }
}
