import React from 'react';
import {
    Autocomplete,
    TextField,
    Container,
    IconButton,
    Modal,
    Fade,
    Box
} from '@mui/material';
import { AppToolbar } from '../../components/AppToolbar';
import { AppButton } from '../../components/AppButton';
import { ArrowBack, Search } from '@mui/icons-material';
import { useLocation, useNavigate } from 'react-router-dom';
import { ClassWorkoutExercisesType } from '../../types/classes.type';
import { ClassesServices } from '../../services/classes.service';
import { useSnackbar } from '../../hooks/snackbar.hook';
import { HttpResponse } from '../../services/base.service';
import { OptionType } from '../../components/AppAutocompleteField';
import { ExerciseType } from '../../types/exercise.type';
import { ExercisesServices } from '../../services/exercises.service';
import { AppBox } from '../../components/AppBox';
import { ConfigService } from '../../services/config.service';

export function CLassExercisesEdit() {
    const navigate = useNavigate();
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const classId = params.get('class_id');
    const workoutId = params.get('workout_id');
    const { showSnackbar } = useSnackbar();

    const [classExercises, setClassExercises] = React.useState<ClassWorkoutExercisesType[]>([]);
    const [exercisesList, setExercisesList] = React.useState<OptionType[]>([]);

    const [preview, setPreview] = React.useState<string | null>(null);

    React.useEffect(() => {
        ClassesServices.getClassExercises(Number(classId), Number(workoutId)).then((resp: HttpResponse<ClassWorkoutExercisesType[]>) => {
            setClassExercises(resp.data);
        }).catch((error) => {
            const msg = (error as any).response?.data?.message || 'An error occurred';
            console.error(msg, error);
            showSnackbar(msg, 'error');
        })

        ExercisesServices.getAll().then((resp: HttpResponse<ExerciseType[]>) => {
            setExercisesList(resp.data.map((e: ExerciseType) => {
                return {
                    id: e.id,
                    name: e.name,
                    video: e.video
                }
            }));
        })

    }, []);

    const handleBack = () => {
        navigate('/classes');
    }


    const handleExerciseChange = (exerciseId: number, stationNumber: number) => {
        //Call the API to update the exercise for the given station
        ClassesServices.updateClassExercise({
            class_id: parseInt(classId!),
            workout_id: parseInt(workoutId!),
            exercise_id: exerciseId,
            station_number: stationNumber,
        })
            .then(() => {
                //Update the local state
                // Update the local state to reflect the changes
                setClassExercises((prevExercises) =>
                    prevExercises.map((exercise) =>
                        exercise.station_number === stationNumber
                            ? { ...exercise, exercise_id: exerciseId, exercise: exercisesList.find((e) => e.id === exerciseId) }
                            : exercise
                    )
                );

                // Show notification
                showSnackbar('Exercise updated successfully!', 'success');
            })
            .catch((error) => {
                const msg = (error as any).response?.data?.message || 'Failed to update exercise';
                console.error(msg, error);
                showSnackbar(msg, 'error');
            });
    };

    const handleOpenPreview = (videoUrl: string) => {
        setPreview(ConfigService.FILE_STORAGE_URL + videoUrl);

    };

    const handleClosePreview = () => {
        setPreview(null);
    };


    return (
        <Container>
            <AppToolbar title={`Edit Class Exercises`}>
                <AppButton startIcon={<ArrowBack />} onClick={handleBack}>
                    Back
                </AppButton>
            </AppToolbar>
            <Container>
                {classExercises.map((classWorkoutExercise: ClassWorkoutExercisesType) => (
                    <AppBox key={classWorkoutExercise.station_number} display="flex">
                        <Autocomplete
                            style={{ flex: 1 }}
                            value={{
                                id: classWorkoutExercise.exercise_id!!,
                                name: classWorkoutExercise.exercise?.name!!
                            }}
                            multiple={false}
                            options={exercisesList}
                            disableClearable
                            getOptionLabel={(option: OptionType) => option.name}
                            isOptionEqualToValue={(option: OptionType, value: OptionType) => option.id === value.id}
                            onChange={(_, selectedOption) => {
                                if (selectedOption) {
                                    handleExerciseChange(selectedOption.id, classWorkoutExercise.station_number!);
                                }
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={'Station ' + classWorkoutExercise.station_number}
                                />
                            )}
                        />
                        <IconButton
                            color="primary"
                            onClick={() => handleOpenPreview(classWorkoutExercise.exercise?.video!!)}
                            disabled={!classWorkoutExercise.exercise?.video}
                        >
                            <Search />
                        </IconButton>
                    </AppBox>
                ))}
                {/* Video Preview Modal */}
                <Modal
                    open={preview !== null}
                    onClose={handleClosePreview}
                    closeAfterTransition
                /*  BackdropComponent={Backdrop}
                 BackdropProps={{
                     timeout: 500,
                 }} */
                >
                    <Fade in={preview !== null}>
                        <Box
                            sx={{
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                width: '80%',
                                bgcolor: 'background.paper',
                                boxShadow: 24,
                                p: 4,
                            }}
                        >
                            {preview && (
                                <video
                                    width="100%"
                                    controls
                                    loop
                                    autoPlay
                                    src={preview}
                                >
                                    Your browser does not support the video tag.
                                </video>
                            )}
                        </Box>
                    </Fade>
                </Modal>
            </Container>
        </Container >
    );
};
