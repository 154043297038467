import { AppTextField } from '../../components/AppTextField';
import { CRUDType } from '../../types/crud.type';
import EditFormComponent from '../common/EditFormComponent';
import { AppFileUploadField } from '../../components/AppFileUploadField';
import { ExerciseType } from '../../types/exercise.type';
import { ExercisesServices } from '../../services/exercises.service';
import { AppAutocompleteField } from '../../components/AppAutocompleteField';
import { EquipmentsServices } from '../../services/equipments.service';
import { TargetAreasServices } from '../../services/targerareas.service';
import { ExerciseTypesServices } from '../../services/exercisetypes.service';
import { AppCheckboxField } from '../../components/AppCheckbox';
import { ValidationUtils } from '../../utils/validations.utils';

export function EditExercise({ mode }: CRUDType) {
    return (
        <EditFormComponent<ExerciseType>
            title="Exercise"
            mode={mode}
            backPath="/exercises"
            service={{
                getById: ExercisesServices.getById,
                add: ExercisesServices.add,
                update: ExercisesServices.update,
            }}
            formFields={[
                { name: 'name', component: AppTextField, props: { label: 'Name', rules: { required: 'Name is required' }, columns: 6 } },
                {
                    name: 'type',
                    component: AppAutocompleteField,
                    props: {
                        label: 'Type',
                        multiple: false,
                        columns: 4,
                        service: {
                            load: ExerciseTypesServices.getAll
                        },
                        rules: {
                            required: ValidationUtils.REQUIRED_FIELD_MESSAGE
                        }
                    }
                },
                {
                    name: 'is_warm_up',
                    component: AppCheckboxField,
                    props: {
                        label: 'Warm Up',
                        columns: 2
                    }
                },
                { name: 'description', component: AppTextField, props: { label: 'Description' } },
                {
                    name: 'exercise_equipments',
                    component: AppAutocompleteField,
                    props:
                    {
                        valueProperty: 'equipment_id',
                        label: 'Equipments',
                        columns: 6,
                        service: {
                            load: EquipmentsServices.getAll
                        },
                        getControlValue: (data: any) => data?.map((obj: any) => {
                            return {
                                id: obj.id,
                                equipment_id: obj['equipment']?.id
                            }
                        })
                    }
                },
                {
                    name: 'exercise_target_areas',
                    component: AppAutocompleteField,
                    props: {
                        valueProperty: 'target_area_id',
                        label: 'Target Areas',
                        columns: 6,
                        service: {
                            load: TargetAreasServices.getAll
                        },
                        getControlValue: (data: any) => data?.map((obj: any) => {
                            return {
                                id: obj.id,
                                target_area_id: obj['target_area']?.id
                            }

                        }),
                        rules: {
                            required: ValidationUtils.REQUIRED_FIELD_MESSAGE
                        }
                    }
                },
                {
                    name: 'image',
                    component: AppFileUploadField,
                    props: {
                        label: 'Choose Exercise Image',
                        columns: 6
                    }
                },
                {
                    name: 'video',
                    component: AppFileUploadField,
                    props: {
                        label: 'Choose Exercise Video',
                        columns: 6,
                        accept: 'video',
                        rules: {
                            required: ValidationUtils.REQUIRED_FIELD_MESSAGE
                        }
                    }
                },
            ]}
        />
    );

}