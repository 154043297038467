import { ConfigService } from "../../services/config.service";
import { ImageUtils } from "../../utils/image.utils"

export function ImageRenderer(params: any) {
    return (
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '100%',
                height: '100%',
            }}
        >
            <img
                src={params.value ? ConfigService.FILE_STORAGE_URL + params.value : ImageUtils.DEFAULT_IMAGE}
                alt="Equipment_Image"
                style={{
                    maxWidth: '100%',
                    maxHeight: '100%',
                    objectFit: 'contain',
                }}
            />
        </div>
    );
};