import { UsersType } from '../types/users.type';
import { HTTPServices } from './base.service';

export type LoginResponseType = { user: UsersType, accessToken: string }
export type LoginRequestType = { email: string, password: string }


export class AuthenticationServices extends HTTPServices {
    private static BASE_ROUTE = 'auth/';

    static signup(data: UsersType) {
        return AuthenticationServices.post<LoginResponseType>(AuthenticationServices.BASE_ROUTE + 'signup', data, 'raw');
    }

    static signin(data: LoginRequestType) {
        return AuthenticationServices.post<LoginResponseType>(AuthenticationServices.BASE_ROUTE + 'signin', data, 'raw');
    }

    static logout() {
        return AuthenticationServices.get<LoginResponseType>(AuthenticationServices.BASE_ROUTE + 'logout');
    }

    static validateUser() {
        return AuthenticationServices.get<LoginResponseType>(AuthenticationServices.BASE_ROUTE + 'validate');
    }

}
