import React from 'react';
import { TextField, Typography, Container, Box, Paper, InputAdornment, IconButton } from '@mui/material';
import { useSnackbar } from '../../hooks/snackbar.hook';
import { AppButton } from '../../components/AppButton';
import { useSession } from '../../hooks/auth.hook';
import { Visibility, VisibilityOff } from '@mui/icons-material';

export function LoginComponent() {
    const { login, error } = useSession();
    const { showSnackbar } = useSnackbar();
    const [email, setEmail] = React.useState('');
    const [password, setPassword] = React.useState('');
    const [showPassword, setShowPassword] = React.useState(false);


    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleSubmit = async (e: React.FormEvent<HTMLDivElement> | undefined) => {
        e?.preventDefault();
        login({ email, password });
    };


    React.useEffect(() => {
        if (error) {
            showSnackbar('Login failed. ' + error, 'error');
        }
    }, [error])

    return (
        <Container component="main" maxWidth="xs">
            <Paper elevation={3} sx={{ padding: 2, marginTop: 8 }}>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Typography component="h1" variant="h5">
                        Login
                    </Typography>
                    <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="Email Address"
                            name="email"
                            autoComplete="email"
                            autoFocus
                            value={email ? email : undefined}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type={showPassword ? 'text' : 'password'}
                            id="password"
                            autoComplete="current-password"
                            value={password ? password : undefined}
                            onChange={(e) => setPassword(e.target.value)}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            edge="end"
                                        >
                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <AppButton
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                        >
                            Login
                        </AppButton>
                    </Box>
                </Box>
            </Paper>
        </Container>
    );
}