import React from 'react';
import './PageNotFound.scss';

export const PageNotFound: React.FC = () => {
    return (
        <div className="notfound-container">
            <span>Page Not Found</span>
            <span>404</span>
        </div>
    )
}