import React from 'react';
import { Grid, Typography, Button, IconButton } from '@mui/material';
import { Control, Controller, useWatch, } from 'react-hook-form';
import { WorkoutPodType, WorkoutPodLapType } from '../../types/workout.type';
import { AppBox } from '../../components/AppBox';
import { AppFieldProps } from '../../types/components.type';
import { MovementsServices } from '../../services/movements.service';
import { MovementType } from '../../types/movement.type';
import { HttpResponse } from '../../services/base.service';
import { TargetAreasServices } from '../../services/targerareas.service';
import { AppTextField } from '../../components/AppTextField';
import { AppAutocompleteField } from '../../components/AppAutocompleteField';
import { ValidationUtils } from '../../utils/validations.utils';
import { TargetAreaType } from '../../types/targetareas.type';
import { Delete as DeleteIcon } from '@mui/icons-material';
import { AppCheckboxField } from '../../components/AppCheckbox';

type WorkoutPodsFieldProps = {
    control: Control<any>;
} & AppFieldProps;

export default function WorkoutPodsField(props: WorkoutPodsFieldProps) {
    const { control } = props;
    const [targetAreas, setTargetAreas] = React.useState<TargetAreaType[]>([]);
    //const { setValue} = useForm({ context: control, });

    // Use a state to trigger re-renders
    const [renderCount, setRenderCount] = React.useState(0);

    const movement: any = useWatch({
        control,
        name: 'movement_id',
        defaultValue: control._formValues['movement_id'] || 0,
    });

    React.useEffect(() => {
        TargetAreasServices.getAll().then((response: HttpResponse<TargetAreaType[]>) => {
            setTargetAreas(response.data);
        });
    }, []);

    React.useEffect(() => {
        if (movement) {
            MovementsServices.getById(movement).then((response: HttpResponse<MovementType>) => {
                const mov = response.data;
                let currentPods = control._formValues['workout_pods'] || [];

                if (mov.pods) {
                    if (mov.pods > currentPods.length) {
                        // Add new pods if the movement requires more pods
                        currentPods = [
                            ...currentPods,
                            ...Array.from({ length: mov.pods - currentPods.length }, () => ({
                                workout_pod_laps: [{ sets: 0, work: 0, rest: 0 }],
                                workout_pod_target_areas: []
                            }))
                        ];
                    } else if (mov.pods < currentPods.length) {
                        // Remove extra pods if the movement requires fewer pods
                        currentPods = currentPods.slice(0, mov.pods);
                    }
                }
                control._formValues['workout_pods'] = currentPods;
                setRenderCount(prev => prev + 1);

            });
        }
    }, [movement]);

    const renderTextField = (name: string, label: string, podIndex: number, lapIndex: number, rules: any) => (
        <Grid item xs={12} sm={4} key={`${podIndex}-${lapIndex}-${name}`}>
            <AppTextField
                name={`workout_pods[${podIndex}].workout_pod_laps[${lapIndex}].${name}`}
                control={control}
                label={label}
                fullWidth
                rules={rules}
            />
        </Grid>
    );

    const getLapsValue = (podIndex: number) => {
        return control._formValues['workout_pods'][podIndex].workout_pod_laps;
    }

    const setLapsValue = (podIndex: number, newValue: any) => {
        control._formValues['workout_pods'][podIndex].workout_pod_laps = newValue;
        // Trigger re-render - setValue isn't triggering a rerender when setting a nested value
        setRenderCount(prev => prev + 1);
    }

    const addLap = (podIndex: number) => {
        const laps = getLapsValue(podIndex);

        const newLaps = [
            ...laps,
            { lap_number: laps.length + 1, sets: 0, work: 0, rest: 0 }
        ]

        setLapsValue(podIndex, newLaps);
    };

    const removeLap = (podIndex: number, lapIndex: number) => {
        let laps = getLapsValue(podIndex);
        laps.splice(lapIndex, 1);
        setLapsValue(podIndex, laps);
    };

    return (
        <AppBox>
            <Controller
                name={'workout_pods'}
                control={control}
                render={({ field, fieldState }) => (
                    <>
                        {fieldState.error && (
                            <AppBox>
                                <Typography color="error">
                                    {fieldState.error.message}
                                </Typography>
                            </AppBox>
                        )}
                        <Grid container spacing={2}>
                            {control._formValues['workout_pods']?.map((pod: WorkoutPodType, podIndex: number) => (
                                <Grid item xs={12} key={podIndex}>
                                    <AppBox p={2} border={1} borderColor="grey.300">
                                        <Typography variant="h6" gutterBottom>
                                            {`POD ${podIndex + 1}`}
                                        </Typography>
                                        <Grid container spacing={2}>
                                            <Grid item xs={10} sm={10}>
                                                <AppAutocompleteField
                                                    label='Target Areas'
                                                    name={`workout_pods[${podIndex}].workout_pod_target_areas`}
                                                    valueProperty="target_area_id"
                                                    control={control}
                                                    service={{
                                                        options: targetAreas || []
                                                    }}
                                                    multiple
                                                />
                                            </Grid>
                                            <Grid item xs={2} sm={2}>
                                                <AppCheckboxField
                                                    name={`workout_pods[${podIndex}].is_amrap`}
                                                    label='Amrap'
                                                    control={control}
                                                />
                                            </Grid>
                                        </Grid>
                                        {pod.workout_pod_laps?.map((lap: WorkoutPodLapType, lapIndex: number) => (
                                            <React.Fragment key={lap.id + '.' + lapIndex}>
                                                <AppBox style={{ display: 'flex', alignItems: 'center', flex: 1, justifyContent: 'center' }}>
                                                    <Typography variant="h6" gutterBottom>
                                                        {`Lap ${lapIndex + 1}`}
                                                    </Typography>

                                                    <IconButton
                                                        aria-label="delete"
                                                        color="error"
                                                        onClick={() => removeLap(podIndex, lapIndex)}
                                                    >
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </AppBox>
                                                <Grid container spacing={2}>
                                                    {renderTextField('sets', 'Number of Sets', podIndex, lapIndex, {
                                                        validate: ValidationUtils.isPositiveInteger
                                                    })}
                                                    {renderTextField('work', 'Work Time (s)', podIndex, lapIndex, {
                                                        validate: ValidationUtils.isPositiveInteger
                                                    })}
                                                    {renderTextField('rest', 'Rest Time (s)', podIndex, lapIndex, {
                                                        validate: ValidationUtils.isInteger
                                                    })}
                                                </Grid>
                                            </React.Fragment>
                                        ))}
                                        <Button onClick={() => addLap(podIndex)} variant="contained" color="primary">
                                            Add Lap
                                        </Button>
                                    </AppBox>
                                </Grid>
                            ))}
                        </Grid>
                    </>
                )}
            />
        </AppBox>
    );
}
