import { TvType } from '../types/tvs.type';
import { HTTPServices } from './base.service';

export class TVsServices extends HTTPServices {
    private static BASE_ROUTE = 'tvs/';

    static getAll() {
        return TVsServices.get<TvType[]>(TVsServices.BASE_ROUTE);
    }

    static getByType(type: TvType['type']) {
        return TVsServices.get<TvType[]>(TVsServices.BASE_ROUTE + `type/${type}`);
    }

}
