export class DateUtils {

    static isISODateFormat(date: string) {
        return /^\d{4}-\d{2}-\d{2}$/.test(date)
    }

    static toTime(date?: Date | string) {
        if (date) {
            const d = typeof date === 'string' ? new Date(date) : date;
            return d.toLocaleTimeString('en-GB', {
                hour: '2-digit',
                minute: '2-digit',
                hour12: false,
            });
        }
        return date;
    }

    static toISODateFormat(date: Date | string) {
        if (date) {
            const d = typeof date === 'string' ? new Date(date) : date;
            const year = d.getFullYear();
            const month = String(d.getMonth() + 1).padStart(2, '0');
            const day = String(d.getDate()).padStart(2, '0');
            return `${year}-${month}-${day}`;
        }
        return date;
    }

    static getWeekNumber(date: Date = new Date()): number {
        const firstDayOfYear = new Date(date.getFullYear(), 0, 1);
        const pastDaysOfYear = (date.getTime() - firstDayOfYear.getTime()) / 86400000;
        return Math.ceil((pastDaysOfYear + firstDayOfYear.getDay() + 1) / 7);
    }

    static startOfDay(date: Date) {
        const start = new Date(date);
        start.setHours(0, 0, 0, 0);
        return start;
    }

    static endOfDay(date: Date) {
        const end = new Date(date);
        end.setHours(23, 59, 59, 999);
        return end;
    }
}