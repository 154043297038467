import { ImageRenderer } from '../../components/renderers/image.renderer';
import { MovementsServices } from '../../services/movements.service';
import ListComponent from '../common/ListComponent';

export function MovementsList() {
    return (
        <ListComponent
            title='Movements'
            getAll={MovementsServices.getAll}
            deleteById={MovementsServices.deleteById}
            columns={
                [
                    {
                        field: 'image',
                        headerName: 'Image',
                        flex: 0.3,
                        renderCell: ImageRenderer
                    },
                    {
                        field: 'name',
                        headerName: 'Name',
                        flex: 1
                    },
                    {
                        field: 'pods',
                        headerName: '# Pods',
                        flex: 0.5
                    },
                    {
                        field: 'stations',
                        headerName: '# Stations',
                        flex: 0.5
                    }
                ]
            }

        />
    );
};