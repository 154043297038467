import { ExerciseType } from '../types/exercise.type';
import { HTTPServices } from './base.service';

export class ExercisesServices extends HTTPServices {
    private static BASE_ROUTE = 'exercises/';

    static getAll() {
        return ExercisesServices.get<ExerciseType[]>(ExercisesServices.BASE_ROUTE);
    }

    static add(data: ExerciseType) {
        return ExercisesServices.post<ExerciseType>(ExercisesServices.BASE_ROUTE, data);
    }
    static update(id: number, data: ExerciseType) {
        return ExercisesServices.put<ExerciseType>(ExercisesServices.BASE_ROUTE + id, data);
    }

    static getById(id: number) {
        return ExercisesServices.get<ExerciseType>(ExercisesServices.BASE_ROUTE + id);
    }

    static deleteById(id: number) {
        return ExercisesServices.delete<ExerciseType[]>(ExercisesServices.BASE_ROUTE + id);
    }
}
