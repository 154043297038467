import { ConfigType } from "../types/config.type";
import { HttpResponse, HTTPServices } from "./base.service";

export class ConfigService extends HTTPServices {
    private static BASE_ROUTE = 'config/';

    static FILE_STORAGE_URL: string | undefined = undefined;

    static async loadConfig() {
        try {
            this.get<ConfigType>(ConfigService.BASE_ROUTE).then((resp: HttpResponse<ConfigType>) => {
                const conf = resp.data;
                ConfigService.FILE_STORAGE_URL = conf.filesStorageUrl;
            })
        } catch (error) {
            console.error('Error fetching configuration:', error);
            throw error;  // Re-throw or handle as necessary
        }
    }
}