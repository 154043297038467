import { WorkoutType } from '../types/workout.type';
import { HTTPServices } from './base.service';

export class WorkoutsServices extends HTTPServices {
    private static BASE_ROUTE = 'workouts/';

    static getAll() {
        return WorkoutsServices.get<WorkoutType[]>(WorkoutsServices.BASE_ROUTE);
    }

    static add(data: WorkoutType) {
        return WorkoutsServices.post<WorkoutType>(WorkoutsServices.BASE_ROUTE, data);
    }
    static update(id: number, data: WorkoutType) {
        return WorkoutsServices.put<WorkoutType>(WorkoutsServices.BASE_ROUTE + id, data);
    }

    static getById(id: number) {
        return WorkoutsServices.get<WorkoutType>(WorkoutsServices.BASE_ROUTE + id);
    }

    static deleteById(id: number) {
        return WorkoutsServices.delete<WorkoutType[]>(WorkoutsServices.BASE_ROUTE + id);
    }
}
