import { ImageRenderer } from '../../components/renderers/image.renderer';
import { ExercisesServices } from '../../services/exercises.service';
import ListComponent from '../common/ListComponent';

export function ExercisesList() {
    return (
        <ListComponent
            title='Exercises'
            getAll={ExercisesServices.getAll}
            deleteById={ExercisesServices.deleteById}
            columns={
                [
                    {
                        field: 'image',
                        headerName: 'Image',
                        flex: 0.3,
                        renderCell: ImageRenderer,
                        sortable: false
                    },
                    {
                        field: 'name',
                        headerName: 'Name',
                        flex: 1
                    },
                    {
                        field: 'type_exercise_type',
                        headerName: 'Type',
                        flex: 1,
                        valueGetter: (params: any) => params?.name
                    },
                    {
                        field: 'description',
                        headerName: 'Description',
                        flex: 1
                    },
                    {
                        field: 'exercise_equipments',
                        headerName: 'Equipments',
                        flex: 1,
                        valueGetter: (row: any) => row.map((value: any) => value.equipment.name).join(', '),
                    },
                    {
                        field: 'exercise_target_areas',
                        headerName: 'Target',
                        flex: 1,
                        valueGetter: (row: any) => row.map((value: any) => value.target_area.name).join(', '),
                    }
                ]
            }

        />
    );
};